.base-button {
  padding: .75rem 2rem;

  color: $white;

  border-radius: 3px;

  background-color: $brand-color-1;
  transition: background-color .3s ease-in-out;

  &:focus,
  &:hover {
    color: $white;
    text-decoration: none;

    background-color: lighten($brand-color-1, 5%);
  }
}