.main-banner {
  position: relative;

  padding: 100px 0 150px;

  color: $white;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;

  &:before {
    content: "";

    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    background-color: #111;
    opacity: 0.6;
    display: inline-block;

  }

  &__caption {
    position: relative;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 60px;

    font-family: 'Poppins', sans-serif;
    font-size: 2.7rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__sub-title {
    margin-bottom: 60px;

    font-family: 'Roboto', sans-serif;
    font-size: 1.42rem;
    line-height: 2.5rem;
  }
}

@include media-tablet {
  .main-banner {
    padding: 120px 0;
  }
}

@include media-mobile {
  .main-banner {
    padding: 30px 0 45px;

    &__caption {
      padding: 0 15px;
    }

    &__title {
      margin-bottom: 15px;

      font-size: 1.625rem;
    }

    &__sub-title {
      margin-bottom: 45px;

      font-size: 1rem;
      line-height: 1.625rem;
    }
  }
}