@import "../utils/variables";

@mixin media-mobile {

  @media (max-width: $mediaWidthMobile) {
      @content;
  }
}


@mixin media-tablet {

    @media (max-width: $mediaWidthTablet) {
        @content;
    }
}

@mixin media-tablet-horizontal {

    @media (max-width: $mediaWidthTabletHorizontal) {
        @content;
    }
}

@mixin media-laptop {

    @media (max-width: $mediaWidthLaptop) {
        @content;
    }
}

@mixin media-custom($width) {

    @media (max-width: $width) {
        @content;
    }
}
