.intro-section {
  padding-bottom: 30px;

  .intro-content {
    a:hover {
      color: $brand-color-1;
    }

    h3 {
      color: $brand-color-1;
    }
  }
}

@include media-tablet-horizontal {
  .intro-section {
    padding-top: 70px;

    &__item {
      padding-bottom: 50px;
    }
  }
}

@include media-mobile {
  .intro-section {
    padding-top: 50px;
  }
}