.top-header {
  padding-top: 40px;
  padding-bottom: 20px;
}

.header-main {
  border-top: none;
}

.ow-navigation {
  display: inline-block;
  float: none;

  .nav.navbar-nav li > a {
    padding: 20px 15px;
  }

  .nav.navbar-nav > li.active > a,
  .nav.navbar-nav > li.active:hover > a,
  .nav.navbar-nav > li.active:focus > a,
  .nav.navbar-nav > li:hover > a,
  .nav.navbar-nav > li:focus > a,
  .nav.navbar-nav > li > a:hover,
  .nav.navbar-nav > li > a:focus,
  .dropdown-menu li:hover > a,
  .dropdown-menu li > a:hover {
    color: $brand-color-1;
    border-top-color: $brand-color-1;
  }

  .nav.navbar-nav .dropdown-menu > li > a {
    padding: 5px;
  }
}

.menu-block {
  background-color: $white;
}

@include media-tablet-horizontal {
  .top-header {
    padding-top: 20px;
    padding-bottom: 0;
  }

  .ow-navigation .nav.navbar-nav li > a {
    padding-top: 10px;
    padding-bottom: 10px;

    text-align: left;
  }

  .ow-navigation {
    .navbar-nav {
      margin-top: 0;
    }
  }

  .navbar-fixed-top .ow-navigation,
  .ow-navigation {
    .navbar-toggle:focus .icon-bar,
    .navbar-toggle:hover .icon-bar,
    .navbar-toggle {
      background-color: $brand-color-1;
    }

    .navbar-toggle:focus,
    .navbar-toggle:hover,
    .navbar-toggle {
      margin-top: 20px;
      margin-bottom: 20px;
      background-color: #fff;

      .icon-bar {
        background-color: #000;
      }
    }
  }
}
