@import "../../local_modules/normalize.css/normalize";

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;

    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
}
