.content {
  font-size: 1.28rem;
  line-height: 1.85rem;

  &__wrapper {
    padding: 50px 0 100px;
  }

  &__title {
    margin-bottom: 20px;

    text-transform: uppercase;
    letter-spacing: -1px;
  }

  &__block {
    padding-top: 20px;
  }

  &__sub-title {
    margin-top: 0;
    margin-bottom: 20px;

    text-transform: capitalize;
    font-size: 1.625rem;
    font-weight: 600;
  }

  &__text {
    margin-bottom: 20px;

    text-align: justify;

    a {
      color: $brand-color-1;
    }
  }

  &__list {
    padding-left: 17px;
    margin-bottom: 20px;

    list-style-type:square;

    &.no-margin-bottom {
      margin-bottom: 0;
    }

    ul {
      list-style-type: circle;
    }
  }

  &__image-wrapper {
    float: right;
    margin-bottom: 30px;
    margin-left: 30px;

    img {
      max-height: 300px;
      max-width: 450px;
    }
  }
}

@include media-tablet-horizontal {
  .content {
    &__image-wrapper {
      float: none;
      margin-left: 0;

      text-align: center;

      img {
        max-width: 100%;
      }
    }
  }
}

@include media-mobile {
  .content {
    &__wrapper {
      padding: 25px 0 50px;
    }
  }
}