.page-banner .banner-content {
  background-color: rgba($brand-color-1, .7);
}

@include media-mobile {
  .page-banner {
    min-height: 160px;

    .banner-content .breadcrumb {
      display: none;
    }
  }
}
