$familyItemGap: 20px;

.families {
  padding-top: 30px;
  padding-bottom: 100px;

  &__title {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1.625rem;
    text-transform: uppercase;
    margin-bottom: 20px;
    letter-spacing: 0;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 (-$familyItemGap) 10px;

    list-style: none;
  }

  &__list-item {
    width: 25%;
    padding: 0 $familyItemGap;
    margin-bottom: 30px;
  }

  &__image-wrapper {
    margin: 0;
    margin-bottom: $familyItemGap;

    border: 7px solid $white;
    box-shadow: 0 2px 2px 2px rgba(0,0,0,0.05);

    img {
      width: 100%;
    }
  }

  &__names {
    margin-bottom: 10px;

    font-size: 1.42rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__welcome-text {
    color: $brand-gray-2;
  }

  &__controls {
    text-align: center;
  }

  &__view-more {
    padding: 1rem 4rem;

    text-transform: capitalize;
  }
}

@include media-tablet-horizontal {
  .families {
    &__list-item {
      width: 33%;
    }
  }
}

@include media-tablet {
  .families {
    padding: 0 15px 60px;

    &__list-item {
      width: 50%;
    }
  }
}

@include media-mobile {
  .families {
    &__list-item {
      width: 100%;
    }
  }
}
