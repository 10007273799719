.footer-main {
  padding: 0;

  background: none;
}

.ftr-widget.about-widget {
  padding-right: 0;
  margin-top: 0;

  border-top: none;
  border-right: none;
}