.ad-logo {
  img {
    max-width: 400px;
  }
}

.ad-logo--text {
  font-family: 'Droid Serif', serif;
  color: $brand-gray-1;
  font-size: 2.5rem;

  &:hover,
  &:focus {
    color: $brand-gray-1;
    text-decoration: none;
  }

  &__colored {
    color: $brand-color-1;
  }
}

.ow-navigation .navbar-brand.ad-logo {
  padding: 25px 0;
  img {
    max-width: 220px;
  }
}

.ow-navigation .navbar-brand.ad-logo--text {
  font-size: 1.42rem;

  .ad-logo__colored {
    display: inline-block;

    font-size: 1.42rem;
    color: $brand-color-1;
  }
}

@include media-tablet {
  .ad-logo img {
    max-width: 300px
  }

  .ow-navigation .navbar-brand.ad-logo img {
    max-width: 220px;
  }
}

@include media-mobile {
  .ad-logo img {
    max-width: 220px;
  }

  .ow-navigation .navbar-brand.ad-logo img {
    max-width: 170px;
  }

  .ad-logo--text {
    font-size: 2rem;
  }
}